import getSSP from '../../lib/getServerSideProps';
import PageComponent from '../../components/Page';

const Programme = (props) => {
  return <PageComponent {...props} />;
};

export default Programme;

export const getServerSideProps = getSSP('programme');
