import { SliceZone } from '@prismicio/react';
import { components } from '../slices';
import { Layout } from '../components/Layout';
import Meta from '../components/Meta';

const Page = ({
  page,
  navigation,
  settings,
  cta = true,
  prepend = true,
  children
}) => {
  return (
    <Layout
      alternateLanguages={page.alternate_languages}
      navigation={navigation}
      settings={settings}
      cta={cta}
      page={page}>
      <Meta page={page} settings={settings} />
      {prepend && children}
      <SliceZone
        context={page}
        slices={page.data.slices}
        components={components}
      />
      {!prepend && children}
    </Layout>
  );
};

export default Page;
